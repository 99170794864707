import React from "react";
import LocalizedLink from "../components/localizedLink";

import SEO from "../components/seo";
import Wrap from "../components/wrap";
import { useTranslation } from "react-i18next";

const thankYouPage = () => {
  const { t } = useTranslation();

  return (
    <>
      <SEO title="Thank you!" keywords={[`Thank you`, `donation`]} />

      <div className="xl:bg-leaf-3">
        <Wrap>
          <h1 className="c-h1"> {t("confirmation.title")}</h1>
          <p className="c-p-lg"> {t("confirmation.text")}</p>
          <LocalizedLink to="/">{t("confirmation.button")}</LocalizedLink>
        </Wrap>
      </div>
    </>
  );
};

export default thankYouPage;
